<template>
  <div>
    <div>
      <vx-card>
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="MAP Report">
              <!-- filter territory -->
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                  <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedTerritories"
                    :options="optionTerritories"
                    :multiple="true"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    :custom-label="customLabel"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- filter SKU code -->
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                  <span>SKU Code</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedSkuCode"
                    :options="optionSkuCode"
                    :multiple="true"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    :custom-label="customSkuCodeLabel"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-button color="success" @click="handleDrawTable()"
                    >Get Data</vs-button
                  >
                </div>
              </div>
              <div class="tab-text">
                <div class="vx-row">
                  <div class="vx-col md:w-1/1 w-full mb-base">
                    <MapTable
                      :draw="this.draw"
                      :territoryIDs="this.territoryIDs"
                      :skuCodeIDs="this.skuCodeIDs"
                    />
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="Export">
              <div class="tab-text">
                <DataTableExport />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import MapTable from './MapTable';
import DataTableExport from './DataTableExport.vue';
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
export default {
  components: {
    MapTable,
    DataTableExport,
  },
  data() {
    return {
      selectedTerritories: [],
      optionTerritories: [],
      selectedSkuCode: [],
      optionSkuCode: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      skuCodeIDs: [],
    };
  },
  methods: {
    ...mapActions({
      getSkuCode: 'reportMAP/getSkuCode',
    }),
    handleDrawTable() {
      this.draw++;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customSkuCodeLabel(val) {
      if (val) {
        return val.name;
      }
    },
  },
  mounted() {
    this.getTerritories();
    this.getSkuCode();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedSkuCode(val) {
      let skuCodeIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          skuCodeIDs.push(e.id);
        }
      });

      this.skuCodeIDs = skuCodeIDs;
    },
    skuCodeList() {
      this.optionSkuCode = this.skuCodeList;
    },
  },

  computed: {
    ...mapState({
      skuCodeList: (state) => state.reportMAP.skuCodeList,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
