<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="dataList" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">

            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            ">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <div class="btn-group ml-5">
                    <vs-button size="small" color="success" type="border"
                        @click="handleExport('excel')">Excel</vs-button>
                </div>
            </template>
            <template slot="thead">
                <vs-th sort-key="material">Material</vs-th>
                <vs-th sort-key="material_description">Material Description</vs-th>
                <vs-th sort-key="plant">Plant</vs-th>
                <vs-th sort-key="currency">Currency</vs-th>
                <vs-th sort-key="price">Price</vs-th>
                <vs-th sort-key="price_unit">Price Unit</vs-th>
                <vs-th sort-key="base_unit_of_measure">Base Unit of Measure</vs-th>
                <vs-th sort-key="last_change">Last Change</vs-th>
                <vs-th sort-key="valuati_on_class">Valuati on Class</vs-th>
                <vs-th sort-key="material_type">Material Type</vs-th>
                <vs-th sort-key="material_group">Material Group</vs-th>
                <vs-th sort-key="price_control">Price Control</vs-th>
                <vs-th sort-key="abc_indicator">ABC Indicator</vs-th>
                <vs-th sort-key="created_by">Created by</vs-th>
                <vs-th sort-key="mrp_type">MRP Type</vs-th>
                <vs-th sort-key="purchasing_group">Purchasing Group</vs-th>
                <vs-th sort-key="valuation_type">Valuation Type</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].material">
                        {{ data[indextr].material }}
                    </vs-td>
                    <vs-td :data="data[indextr].material_description">
                        {{ data[indextr].material_description }}
                    </vs-td>
                    <vs-td :data="data[indextr].plant">
                        {{ data[indextr].plant }}
                    </vs-td>
                    <vs-td :data="data[indextr].currency">
                        {{ data[indextr].currency }}
                    </vs-td>
                    <vs-td :data="data[indextr].price">
                        {{ formatPrice(data[indextr].price) }}
                    </vs-td>
                    <vs-td :data="data[indextr].price_unit">
                        {{ data[indextr].price_unit }}
                    </vs-td>
                    <vs-td :data="data[indextr].base_unit_of_measure">
                        {{ data[indextr].base_unit_of_measure }}
                    </vs-td>
                    <vs-td :data="data[indextr].last_change">
                        {{ dateFormat(data[indextr].last_change) }}
                    </vs-td>
                    <vs-td :data="data[indextr].valuati_on_class">
                        {{ data[indextr].valuati_on_class }}
                    </vs-td>
                    <vs-td :data="data[indextr].material_type">
                        {{ data[indextr].material_type }}
                    </vs-td>
                    <vs-td :data="data[indextr].material_group">
                        {{ data[indextr].material_group }}
                    </vs-td>
                    <vs-td :data="data[indextr].price_control">
                        {{ data[indextr].price_control }}
                    </vs-td>
                    <vs-td :data="data[indextr].abc_indicator">
                        {{ data[indextr].abc_indicator }}
                    </vs-td>
                    <vs-td :data="data[indextr].created_by">
                        {{ data[indextr].created_by }}
                    </vs-td>
                    <vs-td :data="data[indextr].mrp_type">
                        {{ data[indextr].mrp_type }}
                    </vs-td>
                    <vs-td :data="data[indextr].purchasing_group">
                        {{ data[indextr].purchasing_group }}
                    </vs-td>
                    <vs-td :data="data[indextr].valuation_type">
                        {{ data[indextr].valuation_type }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from "moment";

export default {
    props: {
        draw: {
            type: Number,
        },
        territoryIDs: {
            type: Array,
        },
        skuCodeIDs: {
            type: Array,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        ...mapActions({ getMapReport: 'reportMAP/getMapReport' }),
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleExport(file) {
            this.$vs.loading();
            var fileTitle =
                "ARTRANS_REPORT_" +
                (this.territoryNames.length > 0
                    ? this.territoryNames.join("-")
                    : "all") +
                "_";

            if (this.startDocDate || this.endDocDate) {
                fileTitle +=
                    "DOC_DATE_" +
                    moment(this.startDocDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endDocDate).format("YYYY-MM-DD") +
                    "_";
            }

            if (this.startPostingDate || this.endPostingDate) {
                "POSTING_DATE_" +
                    moment(this.startPostingDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endPostingDate).format("YYYY-MM-DD") +
                    "_";
            }

            if (this.startDueDate || this.endDueDate) {
                "DUE_DATE_" +
                    moment(this.startDueDate).format("YYYY-MM-DD") +
                    "_-_" +
                    moment(this.endDueDate).format("YYYY-MM-DD") +
                    "_";
            }

            fileTitle +=
                "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

            this.$http
                .get(this.baseUrl + "/export", {
                    params: {
                        territory_ids: this.territoryIDs,
                        start_posting_date: this.startPostingDate
                            ? moment(this.startPostingDate).format("YYYY-MM-DD")
                            : null,
                        end_posting_date: this.endPostingDate
                            ? moment(this.endPostingDate).format("YYYY-MM-DD")
                            : null,
                        start_doc_date: this.startDocDate
                            ? moment(this.startDocDate).format("YYYY-MM-DD")
                            : null,
                        end_doc_date: this.endDocDate
                            ? moment(this.endDocDate).format("YYYY-MM-DD")
                            : null,
                        start_due_date: this.startDueDate
                            ? moment(this.startDueDate).format("YYYY-MM-DD")
                            : null,
                        end_due_date: this.endDueDate
                            ? moment(this.endDueDate).format("YYYY-MM-DD")
                            : null,
                        open_key_date: this.openKeyDate
                            ? moment(this.openKeyDate).format("YYYY-MM-DD")
                            : null,
                        file: file,
                        title: fileTitle,
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "error") {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "success",
                            title: "Processing",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        dateFormat(val) {
            return moment(val).format("DD MMM YYYY");
        },
        getData() {
            if (this.draw > 0) {
                this.$vs.loading();
                this.getMapReport({
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    territory: this.territoryIDs,
                    sku_code: this.skuCodeIDs,
                }).then(() => {
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            }
        },
        formatPrice(val) {
            if (isNaN(val)) {
                val = 0;
            }
            val = (val / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    computed: {
        ...mapState({
            dataList: (state) => state.reportMAP.dataList,
            totalPage: (state) => state.reportMAP.total_page
        }),
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    }
}
</script>

<style scoped></style>